import React, { useEffect, useState } from "react";
import { useSnapshot } from "valtio";
import { widState } from "../../../store/workdriveState";
import * as ApiCall from "../../../../../API/ApiCalling";

function UseThumbnailFetch(
  file,
  settingId,
  connname,
  orgid,
  apikey,
  datacenterurl,
  settingUrl,
  driveId
) {
  const snap = useSnapshot(widState);

  const [status, setStatus] = useState("idle");
  const [data, setData] = useState([]);

  useEffect(() => {
    if (!file) return;
    const fetchThumbnail = async () => {
      try {
        setStatus((prev) => "fetching");
        if (
          widState?.settingData?.[settingId]?.thumbnailUrls?.hasOwnProperty(
            file?.id
          )
        ) {
          setData(
            (prev) =>
              widState.settingData?.[settingId]?.thumbnailUrls?.[file?.id]
          );
          setStatus((prev) => "fetched");
        } else {
          const response = await ApiCall.getThumbnailData(
            file,
            connname,
            orgid,
            apikey,
            datacenterurl,
            settingUrl,
            driveId
          );

          var blodData = new Blob([response.data]);
          var urlData = URL.createObjectURL(blodData);
          widState.setThumbNail(settingId, file, urlData);
          setData((prev) => urlData);
          setStatus((prev) => "fetched");
        }
      } catch (error) {
        return;
      }
    };
    fetchThumbnail();
  },[file?.id] );
  
  return { status, data };
}

export default UseThumbnailFetch;
