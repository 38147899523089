import React from "react";

import MuiAlert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function SnackAlert({
  snackOpen,
  handleCloseSnack,
  severity,
  message,
  duration,
}) {
  return (
    <Snackbar
      open={snackOpen}
      autoHideDuration={duration}
      onClose={handleCloseSnack}
    >
      <Alert
        onClose={handleCloseSnack}
        severity={severity}
        sx={{ width: "100%", color: "white" }}
      >
        {message}
      </Alert>
    </Snackbar>
  );
}

export default SnackAlert;
