import { useMediaQuery } from "@mui/material";

import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import { useEffect, useState } from "react";
import { useSnapshot } from "valtio";
import { widState } from "../../../store/workdriveState";
import * as ApiCall from "../../../../../API/ApiCalling";
import Response from "./Response";
function RootFolderResponse(props) {
  const {
    EntityId,
    Entity,
    fieldApiName,
    setFieldApiName,
    setRootFolderId,
    recordData,
    fields,
    isAdmin,
    rootFolderId,
    driveId,
    rootFolderData,
    name,
    orgid,
    connname,
    apikey,
    datacenterurl,
    settingUrl,
    settingId,
  } = props;

  const fileLen20 = useMediaQuery("(min-width:1500px)");
  const fileLen18 = useMediaQuery("(min-width:1296px)");
  const fileLen16 = useMediaQuery("(max-width:1295px)");
  // // ;
  const snap = useSnapshot(widState);
  const [post, setPost] = useState([]);
  const [respMessage, setRespMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [searchVal, setSearchVal] = useState("");

  useEffect(() => {
    setSearchVal("");
    if (rootFolderId) {
      if (
        !widState?.settingData?.[settingId]?.previousData?.hasOwnProperty(
          `${rootFolderId}`
        )
      ) {
        async function fetchRootFolderData(params) {
          try {
            widState?.setLoading(true);
            let res = await ApiCall.getFoldersItem(
              rootFolderId,
              connname,
              orgid,
              apikey,
              datacenterurl,
              settingUrl,
              driveId
            );

            if (res?.error) {
              widState?.setLoading(false);
              snap.setIsError(true);
              snap.setErrorMessage(res?.error);
            } else {
              setPost((prev) => res?.data);
              snap.setIsError(false);
              widState.setRootFolderSettingData(
                settingId,
                rootFolderData,
                res.data
              );
              widState?.setLoading(false);
            }
          } catch (error) {
            widState?.setLoading(false);
            snap.setIsError(true);
            return snap.setErrorMessage(error);
          }
        }
        fetchRootFolderData();
      } else {
        widState?.setLoading(false);
        snap.setIsError(false);
        let lastIndex = snap?.settingData?.[settingId]?.breadCrumbs?.length - 1;
        let lastIndexId =
          snap?.settingData?.[settingId]?.breadCrumbs?.[lastIndex]?.id;
        // setPost(snap?.settingData?.[settingId]?.previousData?.[lastIndexId]);
        setPost(snap?.settingData?.[settingId]?.previousData?.[lastIndexId]);

        widState.setRootFolderSettingData(
          settingId,
          rootFolderData,
          snap?.settingData?.[settingId]?.previousData?.[rootFolderId]
        );
        snap?.setLoading(false);
      }
    } else if (driveId) {
      setPost((prev) => rootFolderData);
      widState?.setLoading(false);
    } else {
      widState.setIsError(true);
      snap.setErrorMessage(
        name +
          " folder id is missing. Please provide a valid Drive Folder Id for " +
          name
      );
      widState?.setLoading(false);
    }
  }, [rootFolderId, settingId, rootFolderData?.id]);

  async function handleClick(file, data) {
    if (
      widState.settingData?.[settingId]?.previousData?.hasOwnProperty(file?.id)
    ) {
      setPost(widState.settingData?.[settingId]?.previousData?.[file?.id]);
      setSearchVal("");
      widState?.setLoadedFolderBreadCrumbs(settingId, file, data);
      snap?.setLoading(false);
    } else {
      try {
        snap?.setLoading(true);
        let res = await ApiCall.getFoldersItem(
          file?.id,
          connname,
          orgid,
          apikey,
          datacenterurl,
          settingUrl,
          driveId
        );

        if (res?.error) {
          widState?.setLoading(false);
          snap.setIsError(true);
          return snap.setErrorMessage(res?.error);
        }
        setSearchVal("");
        setPost((prev) => res?.data);
        widState?.setApiSettingData(settingId, file, res.data);
        snap?.setLoading(false);
      } catch (error) {
        widState?.setLoading(false);
        snap.setIsError(true);
        return snap.setErrorMessage(error);
      }
    }
  }

  async function setDetailsViewPost(file, data) {
    // setPost(widState.settingData?.[settingId]?.previousData?.[file?.id]);
    setPost(data);
    setSearchVal("");
    widState?.setRenamedItem(settingId, file, data);
    snap?.setLoading(false);
  }

  return (
    <>
      {driveId && !snap.isError && (
        <>
          <Response
            Entity={Entity}
            EntityId={EntityId}
            rootFolderId={rootFolderId}
            driveId={driveId}
            name={name}
            orgid={orgid}
            isAdmin={isAdmin}
            fieldApiName={fieldApiName}
            setFieldApiName={setFieldApiName}
            setRootFolderId={setRootFolderId}
            recordData={recordData}
            fields={fields}
            connname={connname}
            apikey={apikey}
            datacenterurl={datacenterurl}
            settingUrl={settingUrl}
            settingId={settingId}
            post={post}
            setPost={setPost}
            searchVal={searchVal}
            setSearchVal={setSearchVal}
            handleClick={handleClick}
            setDetailsViewPost={setDetailsViewPost}
          />
        </>
      )}
    </>
  );
}

export default RootFolderResponse;
