import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Divider,
  FormControl,
  InputLabel,
  Link,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";

export default function Overview({
  handleRevokeDropbox,
  handleRevokeZoho,
  revokeLoadingZoho,
  revokeLoadingDropbox,
  authLoadingZoho,
  authLoadingDropbox,
  zohoAuth,
  dropboxAuth,
  handleAuthenticateZoho,
  handleAuthenticateDropbox,
}) {
  return (
    <Box
      sx={{
        py: 1,
        width: "85%",
        mx: "auto",
        boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
        borderRadius: "10px",
        mt: 2,
        bgcolor: "#fff",
      }}
    >
      <Box sx={{ px: 4 }}>
        <Typography
          sx={{
            mt: 2,
            mb: 1,
            color: "rgba(0, 0, 0, 0.87)",
            fontSize: "24px",
            fontWeight: 600,
          }}
        >
          Authentication
        </Typography>
        <Divider />
        <br />
        {/* <Typography
          sx={{
            my: 1,
            color: "rgba(0, 0, 0, 0.87)",
            fontWeight: "bold",
          }}
        >
          Overview
        </Typography> */}
        <Box
          sx={{
            color: "rgba(0, 0, 0, 0.6)",
            fontSize: "16px",
            fontWeight: 400,
            lineHeight: "24px",

            "li:not(:last-child)": {
              margin: "0 0 10px 0",
            },
          }}
        >
          Share Point is an online cloud storage service from Microsoft, which
          needs to integrate using Rest API. To do Read/Create/Edit/Delete
          Files/Folders in Share Point, you need to authenticate Sharepoint and
          Zoho once to make Widgets/Workflow functional
        </Box>
        {/* <Typography
          sx={{
            my: 1,
            color: "rgba(0, 0, 0, 0.87)",
            fontWeight: "bold",
          }}
        >
          Select Data Center URL
        </Typography>
        <Box
          sx={{
            color: "rgba(0, 0, 0, 0.6)",
            fontSize: "16px",
            fontWeight: 400,
            linetHeight: "24px",
          }}
        >
          Based on diffent ZOHO Data Center you need to select appropriate Data
          Center URL and Hit Authenticate and follow the steps to proceed.
        </Box> */}
      </Box>
      <Box sx={{ ml: 7, mt: 3 }}>
        {dropboxAuth ? (
          <Button
            disabled={revokeLoadingDropbox}
            onClick={handleRevokeDropbox}
            sx={{
              mt: 2.5,
              mb: 2,
              mr: 2,
              textTransform: "none",
              width: "14rem",
              borderRadius: "8px",
              height: "2.9rem",
              bgcolor: "#1976D2",
            }}
            variant="contained"
          >
            {revokeLoadingDropbox ? (
              <Box sx={{ display: "flex", alignItems: "center" }}>
                loading{" "}
                <CircularProgress sx={{ color: "white", ml: 1 }} size={17} />{" "}
              </Box>
            ) : (
              "Revoke Sharepoint"
            )}
          </Button>
        ) : (
          <Button
            disabled={authLoadingDropbox}
            onClick={() => {
              handleAuthenticateDropbox();
            }}
            sx={{
              mt: 2.5,
              mb: 2,
              mr: 2,
              textTransform: "none",
              width: "14rem",
              borderRadius: "8px",
              height: "2.9rem",
              bgcolor: "#1976D2",
            }}
            variant="contained"
          >
            {authLoadingDropbox ? (
              <Box sx={{ display: "flex", alignItems: "center" }}>
                Verifying{" "}
                <CircularProgress sx={{ color: "white", ml: 1 }} size={17} />{" "}
              </Box>
            ) : (
              "Authenticate Sharepoint"
            )}
          </Button>
        )}

        {zohoAuth ? (
          <Button
            disabled={revokeLoadingZoho}
            onClick={handleRevokeZoho}
            sx={{
              mt: 2.5,
              mb: 2,
              textTransform: "none",
              width: "14rem",
              borderRadius: "8px",
              height: "2.9rem",
              bgcolor: "#1976D2",
            }}
            variant="contained"
          >
            {revokeLoadingZoho ? (
              <Box sx={{ display: "flex", alignItems: "center" }}>
                loading
                <CircularProgress
                  sx={{ color: "white", ml: 1 }}
                  size={17}
                />{" "}
              </Box>
            ) : (
              "Revoke ZOHO"
            )}
          </Button>
        ) : (
          <Button
            disabled={authLoadingZoho}
            onClick={() => {
              handleAuthenticateZoho();
            }}
            sx={{
              mt: 2.5,
              mb: 2,
              textTransform: "none",
              width: "14rem",
              borderRadius: "8px",
              height: "2.9rem",
              bgcolor: "#1976D2",
            }}
            variant="contained"
          >
            {authLoadingZoho ? (
              <Box sx={{ display: "flex", alignItems: "center" }}>
                Verifying{" "}
                <CircularProgress sx={{ color: "white", ml: 1 }} size={17} />{" "}
              </Box>
            ) : (
              "Authenticate ZOHO"
            )}
          </Button>
        )}
      </Box>
    </Box>
  );
}
