import {
  Autocomplete,
  Box,
  Button,
  Divider,
  Grid,
  Modal,
  TextField,
  Typography,
} from "@mui/material";

import ClearIcon from "@mui/icons-material/Clear";
import { useEffect, useState } from "react";
import folderTree from "../../../folderTree.svg";
import "./AddCustomModule.css";
import RootFolderSelection from "../RootFolderSelection/RootFolderSelection";
import { useSnapshot } from "valtio";
import { widState } from "../../../Store/workdriveState";
import AddIcon from "@mui/icons-material/Add";
const ZOHO = window.ZOHO;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: 550,
  borderRadius: "20px",
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  py: 4,
};

export default function AddCustomModule({
  setSelectedModule,
  selectedModule,
  datacenterurl,
}) {
  const snap = useSnapshot(widState);
  const [activeClass, setActiveClass] = useState("Leads");
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setTriggerFetch(!triggerFetch);
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  const [defaultModule, setDefaultModule] = useState([
    "Leads",
    "Contacts",
    "Accounts",
    "Deals",
    "Campaigns",
  ]);

  const [triggerFetch, setTriggerFetch] = useState(false);
  const [moduleList, setModuleList] = useState([]);
  const [fieldList, setFieldList] = useState([]);
  const [selectedModuleValue, setSelectedModuleValue] = useState(null);
  const [selectedFieldValue, setSelectedFieldValue] = useState(null);
  const [firstCreateBtnHit, setFirstCreateBtnHit] = useState(false);

  useEffect(() => {
    async function initZoho() {
      ZOHO.CRM.META.getModules().then(function (data) {
        let resp = data?.modules;
        let finalResp = resp?.map((item) => {
          if (item.module_name && item.plural_label) {
            return {
              displayName: item.plural_label,
              apiName: item.api_name,
            };
          }
        });
        const filteredResp = finalResp?.filter((item) => Boolean(item));
        setModuleList(filteredResp);
      });
    }
    initZoho();
  }, [triggerFetch]);

  const handleFieldFetch = async (apiName) => {
    ZOHO.CRM.META.getFields({ Entity: apiName }).then(function (data) {
      // // ;
      const resp = data?.fields;
      let finalResp = resp?.map((item) => {
        if (item.field_label && item.api_name && item.data_type === "text") {
          return {
            displayName: item.field_label,
            apiName: item.api_name,
          };
        }
      });
      const filteredResp = finalResp?.filter((item) => Boolean(item));
      setFieldList(filteredResp);
    });
  };

  const handleCreateCustomModule = () => {
    setFirstCreateBtnHit((prev) => true);
    if (selectedModuleValue && selectedFieldValue) {
      let modules = defaultModule;
      modules.push(selectedModuleValue.displayName);
      setDefaultModule([...new Set(modules)]);
      handleClose();
    }
  };

  return (
    <Box>
      <Box
        sx={{
          // display: "flex",
          // justifyContent: "space-between",
          // py: 1,
          px: { xs: 8, xl: 5 },
          // bgcolor: "rgba(24, 80, 160, 0.04)",
        }}
      >
        <Box sx={{ bgcolor: "white", borderRadius: "20px" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              // bgcolor: "yellow",
            }}
          >
            <Box sx={{ p: 1 }}>
              {Object.keys(snap.settingData)?.map((module) => {
                return (
                  <button
                    onClick={() => {
                      setSelectedModule(module);
                      widState.setSelectedSetting(module);
                    }}
                    className={
                      snap?.selectedSetting === `${module}`
                        ? snap.settingData[module]?.rootFolder?.id
                          ? "activeModuleBtn"
                          : "nonSharedPointActive"
                        : snap.settingData[module]?.rootFolder?.id
                        ? "nonactiveModuleBtn"
                        : "nonSharedPoint"
                    }
                  >
                    {module}
                  </button>
                );
              })}
            </Box>
            <Box sx={{ bgcolor: "#EAF4FF", borderRadius: "1.5rem", p: 1 }}>
              {["Historical Data"]?.map((module) => {
                return (
                  <button
                    onClick={() => {
                      setSelectedModule(module);
                      // widState.setSelectedSetting(module);
                    }}
                    className={
                      selectedModule === `${module}`
                        ? "activeModuleBtnNew"
                        : "moduleBtn"
                    }
                  >
                    {module}
                  </button>
                );
              })}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
