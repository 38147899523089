import React, { useEffect, useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useSnapshot } from "valtio";
import { widState } from "../../../../../../Store/workdriveState";
// import { widState } from "../../../store/workdriveState";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import { Box } from "@mui/system";

let snackMessage = {
  bool: true,
  type: "Success",
  message: "Link has been copied",
};

function ClipboardCopy({ color, copyText }) {
  const [isCopied, setIsCopied] = useState(false);
  const snap = useSnapshot(widState);
  return (
    <Box
      sx={{
        bgcolor: !color && "#103254",
        padding: "5px",
        borderRadius: "5px",
        mt: "-5px",
        cursor: "pointer",
      }}
    >
      <CopyToClipboard
        text={copyText}
        onCopy={() => {
          snap.setSnackbar(snackMessage);
          setIsCopied((prev) => true);
          setTimeout(() => {
            setIsCopied((prev) => false);
          }, 1000);
        }}
      >
        <Box>
          {isCopied ? (
            <DoneAllIcon sx={{ color: color && "green" }} />
          ) : (
            <ContentCopyIcon sx={{ color: color && "black" }} />
          )}
        </Box>
      </CopyToClipboard>
    </Box>
  );
}

export default ClipboardCopy;
