import * as React from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from "@mui/material";
import Button from "@mui/material/Button";
import { Controller, useForm } from "react-hook-form";
import { useEffect } from "react";

function RenameDetailsModal({
  open,
  handleClose,
  handleSubmit,
  control,
  onSubmit,
  file,
}) {
  const [name, setName] = React.useState(file?.name?.split(".")?.[0]);

  useEffect(() => {
    setName(file?.name?.split(".")?.[0]);
  }, [file]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "400px", // Set your width here
            padding: "10px",
          },
        },
      }}
    >
      <DialogTitle
        id="alert-dialog-title"
        sx={{ fontSize: "23px", fontWeight: 500 }}
      >
        {"Rename"}
      </DialogTitle>
      <DialogContent>
        <TextField
          sx={{ marginTop: "10px", mr: 2, color: "#1976D2" }}
          id="outlined-controlled"
          label="Rename"
          value={name}
          onChange={(event) => {
            setName(event.target.value);
          }}
        />
        <Button
          sx={{ marginTop: "10px", mr: 2, color: "#1976D2" }}
          onClick={handleClose}
          color="secondary"
        >
          Cancel
        </Button>
        <Button
          sx={{ marginTop: "10px", bgcolor: "#1976D2" }}
          onClick={() => {
            onSubmit({ name: name });
          }}
          variant="contained"
        >
          Rename
        </Button>
      </DialogContent>
    </Dialog>
  );
}

export default RenameDetailsModal;
