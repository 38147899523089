import React from "react";

import Modal from "@mui/material/Modal";
import { Box } from "@mui/system";

import CancelIcon from "@mui/icons-material/Cancel";
import { useSnapshot } from "valtio";
import { widState } from "../../../store/workdriveState";
const imgWidth = window.screen.width / 2.5;
const imgHeight = window.screen.height / 2.5;

const style = {
  position: "relative",
  display: "flex",
  justifyContent: "center",
  // width: `${imgWidth}px`,
  top: "50%",
  left: "50%",
  // maxHeight: `${imgHeight}px`,
  transform: "translate(-50%, -50%)",
  outline: "none",
  // background: "#ffffff",
};

const buttonBoxStyle = {
  position: "absolute",
  top: "5%",
  left: "97%",
  transform: "translate(-50%, -50%)",
  msTransform: "translate(-50%, -50%)",

  zIndex: 999,
};

const buttonStyle = {
  color: "white",
  cursor: "pointer",
  fontSize: "80",
  color: "warning",
};

function ModalImage({ open, setOpen, profileImage }) {
  const snap = useSnapshot(widState);

  const handleClose = () => {
    snap?.setLoading(false);
    setOpen(false);
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      onClick={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Box sx={buttonBoxStyle}>
          <CancelIcon
            fontSize="large"
            color="action"
            cursor="pointer"
            onClick={handleClose}
            sx={{ color: "#fff" }}
          />
        </Box>
        <Box sx={{ height: "450px", bgcolor: "#fff", p: 2 }}>
          <img height="100%" alt="" src={profileImage} />
        </Box>
      </Box>
    </Modal>
  );
}

export default ModalImage;
