import { useMediaQuery } from "@mui/material";
import { useEffect, useState } from "react";

function useFileNameLength(name) {
  const fileLen20 = useMediaQuery("(min-width:1500px)");
  const fileLen18 = useMediaQuery("(min-width:1296px)");
  const fileLen16 = useMediaQuery("(max-width:1295px)");
  // //

  const [nameLen, setNameLen] = useState(16);

  useEffect(() => {
    if (fileLen20) {
      setNameLen(19);
    } else if (fileLen18) {
      setNameLen(16);
    } else if (fileLen16) {
      setNameLen(14);
    }
  });

  return { nameLen };
}

export default useFileNameLength;
