import * as React from "react";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

export default function FAQ() {
  const [expanded, setExpanded] = React.useState("panel1");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <Box
      sx={{
        p: 2,
        width: "85%",
        mx: "auto",
        boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
        borderRadius: "10px",
        mb: 3,
        bgcolor: "#fff",
        mt: 2,
      }}
    >
      {/* Feature Overview */}
      <Accordion
        expanded={expanded === "overview"}
        onChange={handleChange("overview")}
      >
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <Typography>Features Overview</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <h3>Default Features Overview</h3>
            After Installation and Authentication, you are able to use following
            features by Default
            <ol class="ql-rendered-list-container" data-is-root="true">
              <li data-block-id="block-720851b9-10fe-4683-870c-081df64b56de">
                On create record, Share Point  Folder will be created for
                Leads/Contacts/Accounts/Deals/Campaigns.
              </li>
              <li data-block-id="block-15cae1d9-3dee-4151-b4b4-d910dcd40c6d">
                Related List widgets in Leads/Contacts/Accounts/Deals/Campaigns
                allows you to View/Create/Delete/Copy/Move/Share/Upload
                Files/Folders
              </li>
            </ol>
          </Typography>
          <Typography>
            <h3>Additional Features</h3>
            You can extend following functionality using the Extension Further:
            <ol class="ql-rendered-list-container" data-is-root="true">
              <li data-block-id="block-720851b9-10fe-4683-870c-081df64b56de">
                Assign it in Workflow for any module, which allow you to Create
                Folder, Edit Folder Name and Delete Folder.
              </li>
              <li data-block-id="block-720851b9-10fe-4683-870c-081df64b56de">
                Add it as Related List to any Custom Module to Visualize and
                perform different action in Share Point .
              </li>
              <li data-block-id="block-720851b9-10fe-4683-870c-081df64b56de">
                Create Public Share Link from Workflow (Create) or from Related
                List Widget.
              </li>
            </ol>
          </Typography>
        </AccordionDetails>
      </Accordion>
      {/* How to Authenticate */}
      <Accordion
        expanded={expanded === "authenticate"}
        onChange={handleChange("authenticate")}
      >
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <Typography>How to Auththenicate</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <h3>Authentication Process</h3>
          <Typography>
            <ol class="ql-rendered-list-container" data-is-root="true">
              <li data-block-id="block-f9e13cef-42f5-4e69-ad73-c0945becae6c">
                Go to the URL:{" "}
                <a
                  data-link-card="true"
                  href={`https://crm.zoho.com/crm/settings/extensions/all`}
                  target="_blank"
                >
                  CRM Marketplace
                </a>
              </li>
              <li data-block-id="block-02664801-1578-49f8-8ba3-63df89a24dfe">
                From the available options, select "Installed"
              </li>
              <li data-block-id="block-3ed914e7-82ae-4e57-b7ae-c7195e4171a8">
                Under "Share Point  for Zoho CRM" extension click on its
                "Settings" button.
              </li>
              <li data-block-id="block-2639f3e5-8c49-4e01-a556-28d430216154">
                Navigate to the "Authenticate" section. If already
                authenticated, it will redirect to Module Settings.
              </li>
              <li data-block-id="block-0b8233fa-6720-47ab-bb02-f8ac643e1fdd">
                Choose your desired "Data Center URL".
              </li>
              <li data-block-id="block-e62a740d-3d6c-420d-9fd6-22199dc55788">
                Select the appropriate CRM organization, and proceed with the
                authentication process.
              </li>
              <li data-block-id="block-6a983c56-9242-48a7-bcad-f5ca06f09860">
                Close the window once authenticated, Extension will
                automatically verify the Authentication
              </li>
            </ol>
          </Typography>
        </AccordionDetails>
      </Accordion>
      {/* How to Edit Setting generated by Extension for Leads/Contacts/Accounts/Deals/Campaign */}
      <Accordion
        expanded={expanded === "panel2"}
        onChange={handleChange("panel2")}
      >
        <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
          <Typography>
            How to Edit Setting generated by Extension for
            Leads/Contacts/Accounts/Deals/Campaign
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <h3>Updating Default Settings</h3>
          <Typography>
            Default Settings are created for
            Leads/Contacts/Accounts/Deals/Campaigns at the time of Extension
            Installation. You can change the Folder Structure from Extension
            Settings Page. Using # you can add Merge Fields, + will add a Sub
            Folder, - to remove the Sub Folder. Save the Settings once done
            editing.
          </Typography>
        </AccordionDetails>
      </Accordion>
      {/* How to Add Settings on Workflow to Create/Edit/Delete Functionalites for all Module */}
      <Accordion
        expanded={expanded === "panel3"}
        onChange={handleChange("panel3")}
      >
        <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
          <Typography>
            How to Add Settings on Workflow to Create/Edit/Delete Functionalites
            for all Module
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            You can add settings to any Workflow to Create/Rename/Delete Folder.
            In Instant Action, at the Button, you will find a Option named
            Create Workdive Setting(widgetsforSharePoint ). <br />
            <br /> You can do following actions in Custom Action of Workflow
            <ol class="ql-rendered-list-container" data-is-root="true">
              <li data-block-id="block-f9e13cef-42f5-4e69-ad73-c0945becae6c">
                Create Folder and Sub-Folder.
              </li>
              <li data-block-id="block-02664801-1578-49f8-8ba3-63df89a24dfe">
                Create Shareable Link (Download Only) on Create Action
              </li>
              <li data-block-id="block-3ed914e7-82ae-4e57-b7ae-c7195e4171a8">
                Rename Existing Folder on Edit Action
              </li>
              <li data-block-id="block-2639f3e5-8c49-4e01-a556-28d430216154">
                Delete Existing Folder. <br />
              </li>
              <strong>PS:</strong> You need to achieve this on Edit Workflow,
              can not be used on Delete Workflow, as once Record is deleted, we
              dont have information of Existing Record Data
            </ol>
          </Typography>
        </AccordionDetails>
      </Accordion>
      {/* How to Disable System Generated Workflow */}
      <Accordion
        expanded={expanded === "panel4"}
        onChange={handleChange("panel4")}
      >
        <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
          <Typography>How to Disable System Generated Workflow</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            By Default, while installing, On Create Workflow is created for
            Leads/Contacts/Accounts/Deals/Campaigns. <br /> If you dont want to
            crate Folder on Create of Record and want to create Folder under
            Certain Condition, you need to disable the Default Workflow first.
            <br />
            Go to Workflow Section, find the workflow and deactivate it.
          </Typography>
        </AccordionDetails>
      </Accordion>
      {/* How to add Nested Folder on Create of Record */}
      <Accordion
        expanded={expanded === "panel5"}
        onChange={handleChange("panel5")}
      >
        <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
          <Typography>How to add Nested Folder on Create of Record</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            While providing Folder Name, you have option for +/-. When click +
            you can create a Sub Folder, clicking - will delete the Sub Folder
            and its nested folders. You can Drag and Drop to Re-Sructure the
            Folder Structure. Type # to use Merge Fields for Record and all All
            Related Lookup Fields.
          </Typography>
        </AccordionDetails>
      </Accordion>
      {/* How to add Subfolder on Record's Folder on Certain Condition */}
      <Accordion
        expanded={expanded === "panel6"}
        onChange={handleChange("panel6")}
      >
        <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
          <Typography>
            How to add Subfolder on Record's Folder on Certain Condition
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <ul>
              <li>
                Navigate to the "Workflow" section and create a new workflow.
              </li>
              <li>Set the trigger when "On a record action" is created.</li>
              <li>
                In the "Conditions" section, configure the conditions as
                required.
              </li>
              <li>
                In the "Action" section, select "Custom Action Test
                (ConnectedApp)".
              </li>
              <li>From the list of actions, select "Create Folder".</li>
              <li>
                Decide if you want to save the folder ID for individual records
                or not. If yes, select the field to save the folder ID.
              </li>
              <li>
                Choose the root folder, either a specific folder or using the
                folder ID previously saved in the record(in this case you need
                to select the field).{" "}
              </li>
              <li>
                Configure the folder structure, including dynamic naming by
                pressing # and selecting fields.
              </li>
              <li>For Nested folder press the + icon to add subfolder.</li>
              <li>Press the "Save" button to apply the changes and enjoy.</li>
            </ul>
          </Typography>
        </AccordionDetails>
      </Accordion>
      {/* How to process Historical Records with Current Settings Created */}
      {/* <Accordion
        expanded={expanded === "panel7"}
        onChange={handleChange("panel7")}
      >
        <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
          <Typography>
            How to process Historical Records with Current Settings Created
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
            malesuada lacus ex, sit amet blandit leo lobortis eget. Lorem ipsum
            dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada
            lacus ex, sit amet blandit leo lobortis eget.
          </Typography>
        </AccordionDetails>
      </Accordion> */}
      {/* How to Use Existing Settings in Buttons for On Click Functionalities */}
      {/* <Accordion
        expanded={expanded === "panel8"}
        onChange={handleChange("panel8")}
      >
        <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
          <Typography>
            How to Use Existing Settings in Buttons for On Click Functionalities
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
            malesuada lacus ex, sit amet blandit leo lobortis eget. Lorem ipsum
            dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada
            lacus ex, sit amet blandit leo lobortis eget.
          </Typography>
        </AccordionDetails>
      </Accordion> */}
      {/* Can i get support? */}
      <Accordion
        expanded={expanded === "panel9"}
        onChange={handleChange("panel9")}
      >
        <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
          <Typography>Can i get support?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Certainly! Our team is always available to provide you with the
            support you need. We believe in delivering exceptional customer
            service and strive to ensure that all of your questions and concerns
            are addressed in a timely and efficient manner. Whether you need
            help with a technical issue or just have some questions about our
            services, our support team is here to assist you every step of the
            way. Don't hesitate to reach out to us, we're here to help!
          </Typography>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}
