import React from "react";
import ModalFile from "../Modals/ModalFile";
import ModalImage from "../Modals/ModalImage";
import ModalPdf from "../Modals/ModalPdf";

function ModalDecider({
  open,
  setOpen,
  profileImage,
  file,
  settingId,
  datacenterurl,
  connname,
  orgid,
  apikey,
  settingUrl,
  driveId
}) {
  switch (file?.type) {
    case "image":
      return (
        <ModalImage open={open} setOpen={setOpen} profileImage={profileImage} />
      );
      break;
    case "pdf":
      return (
        <ModalPdf
          width="100%"
          alt=""
          open={open}
          setOpen={setOpen}
          profileImage={profileImage}
        />
      );
      break;
    default:
      return (
        <ModalFile
          width="100%"
          alt=""
          open={open}
          setOpen={setOpen}
          profileImage={""}
          file={file}
          datacenterurl={datacenterurl}
          settingId={settingId}
          connname={connname}
          orgid={orgid}
          apikey={apikey}
          settingUrl={settingUrl}
          driveId={driveId}
        />
      );
      break;
  }
}

export default ModalDecider;
