import { useEffect, useState } from "react";

import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Settings from "./Pages/Settings";
import CustomAction from "./Pages/CustomAction";
import RelatedList from "./Pages/RelatedList";
import { useSnapshot } from "valtio";
import { widState } from "./Store/workdriveState";

const ZOHO = window.ZOHO;

function App() {
  const snap = useSnapshot(widState);
  const [zohoLoaded, setZohoLoaded] = useState(false);
  const router = createBrowserRouter([
    {
      path: "/relatedlist",
      element: <RelatedList />,
    },
    {
      path: "/settings",
      element: <Settings />,
    },
    {
      path: "/customaction",
      element: <CustomAction />,
    },
    {
      path: "/webtab",
      element: <Settings />,
    },
  ]);

  return <RouterProvider router={router} />;
}

export default App;
