import * as React from "react";
import Modal from "@mui/material/Modal";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import StopCircleIcon from "@mui/icons-material/StopCircle";
import * as ApiCall from "../../API/ApiCalling";
import { Box } from "@mui/system";
import {
  Autocomplete,
  Button,
  Chip,
  CircularProgress,
  createStyles,
  Divider,
  Grid,
  TablePagination,
  Tooltip,
  Typography,
} from "@mui/material";
import useCrmVariable from "../../../CustomHook/useCrmVariable";
import { useEffect } from "react";
import { useState } from "react";
import ProcessHistoricData from "../ProcessHistoricData/ProcessHistoricData";
import dayjs from "dayjs";
import axios from "axios";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "rgba(25, 118, 210, 0.12)",
    color: "black",
    border: "none !important",
    padding: "7px 5px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: "0 5px",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // width: 400,
  bgcolor: "background.paper",
  // border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function CronLIst() {
  const { orgid, connname, apikey, zuid, datacenterurl, handleClickOpen, zapiKey } =
    useCrmVariable({});
  const [crons, setCrons] = useState([]);
  const [loading, setLoading] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [individualCron, setIndividualCron] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const [fetchingCronData, setFetchingCronData] = useState(true);
  const [inProgressModuleList, setInProgressModuleList] = useState([]);
  const [activeCrons, setActiveCrons] = useState(null);
  const [isOpenCreateCronModal, setOpenCreateCronModal] = useState(false);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  useEffect(() => {
    async function fetchCrons() {
      try {
        setFetchingCronData(true);
        const cronsResp = await ApiCall.getCronJobs({
          orgid,
          connname,
          apikey,
        });

        
        let filteredList = cronsResp?.data
          ?.filter((item) => item.status === "In Progress")
          ?.map((el) => el?.req_body?.module?.apiName);

        setInProgressModuleList(filteredList);

        setPage(0);
        setRowsPerPage(5);
        setCrons((prev) => cronsResp?.data);
        setActiveCrons((prev) =>
          cronsResp?.data?.filter((cron) => cron?.status != "Completed")
        );
        setFetchingCronData(false);
      } catch (error) {
        setFetchingCronData(false);
      }
    }
    if (orgid && connname && apikey) {
      fetchCrons();
    }
  }, [toggle]);

  const visibleRows = React.useMemo(
    () => crons.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
    [page, rowsPerPage, crons]
  );

  const handleModalOpen = () => {
    setOpenCreateCronModal((prev) => true);
  };

  const handleClose = () => setOpenCreateCronModal(false);

  const handleOpenModal = (type, data) => {
    setIndividualCron({ type: type, data: data });
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setIndividualCron({});
    setOpenModal(false);
  };

  const handleCronUpdate = async () => {
    // return;
    try {
      setLoading(true);
      if (individualCron.type === "stop") {
        let reqBody = {
          url: process.env.REACT_APP_ADMIN_SERVER_URL + `/utils/cronjobs`,
          method: "PUT",
          headers: {
            orgid: orgid, // Org ID
            apikey: apikey, // API KEy
            connname: "easysharepointforcrm", // Conn Name
          },
          data: {
            settingId: individualCron?.data?.setting_id,
            settingStatus: "Stopped",
          },
        };
        const resp = await axios.request(reqBody);
        setLoading(false);
        handleCloseModal();
        setToggle(!toggle);
      } else {
        let reqBody = {
          url: process.env.REACT_APP_ADMIN_SERVER_URL + `/utils/cronjobs/${individualCron?.data?.setting_id}`,
          method: "DELETE",
          headers: {
            orgid: orgid, // Org ID
            apikey: apikey, // API KEy
            connname: "easysharepointforcrm", // Conn Name
          },
        };
        const resp = await axios.request(reqBody);
        setLoading(false);
        handleCloseModal();
        setToggle(!toggle);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <Box
      sx={{
        p: 2,
        width: "89%",
        mx: "auto",
        boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
        borderRadius: "10px",
        bgcolor: "#fff",
        mt: 2,
      }}
    >
      <Box sx={{ display: "flex", justifyContent: "space-between", mb: 1 }}>
        <Typography
          sx={{
            fontSize: 22,
            fontWeight: 500,
            letterSpacing: "0.25px",
            fontFamily: "Roboto",
          }}
        >
          Historical Data Log
        </Typography>
        <Button variant="contained" sx={{ mb: 0.5 }} onClick={handleModalOpen}>
          Create Process
        </Button>
      </Box>

      {/* <Divider /> */}

      <Box>
        {fetchingCronData ? (
          <Typography sx={{ textAlign: "center", my: 5, fontSize: 20 }}>
            Please wait while fetching data <CircularProgress size={16} />
          </Typography>
        ) : (
          <>
            <TableContainer component={Paper}>
              <Table size="small" aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell align="left">Date</StyledTableCell>
                    <StyledTableCell align="left">Module Name</StyledTableCell>
                    <StyledTableCell align="left">
                      Custom View Name
                    </StyledTableCell>
                    <StyledTableCell align="center">Status</StyledTableCell>
                    <StyledTableCell align="center">Actions</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {/* {JSON.stringify(visibleRows)} */}
                  {crons.length > 0 ? (
                    visibleRows.map((row, index) => (
                      <StyledTableRow key={index}>
                        <StyledTableCell align="left">
                          {row?.created_at
                            ? dayjs(row?.created_at).format("MM-DD-YYYY HH:mm")
                            : ""}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {row?.req_body?.module?.displayName}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {row?.req_body?.customView?.name}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {/* <Typography
                        sx={{
                          borderRadius: 10,
                          fontSize: 14,
                          py: 0.5,
                          textAlign: "center",
                          bgcolor: ["In Progress", "Completed"].includes(
                            row?.status
                          )
                            ? "rgba(102, 187, 106, 0.5)"
                            : "#FFF9C4",
                          color: ["In Progress", "Completed"].includes(
                            row?.status
                          )
                            ? "rgba(102, 187, 106, 1)"
                            : "black",
                        }}
                      >
                        {row?.status}
                      </Typography> */}
                          {row?.status === "Completed" && (
                            <Chip
                              sx={{
                                fontWeight: 500,
                                width: 95,
                                height: 25,
                                bgcolor: "rgba(102, 187, 106, 0.4)",
                                color: "#036e07",
                              }}
                              label={row?.status}
                            />
                          )}
                          {row?.status === "In Progress" && (
                            <Chip
                              sx={{
                                fontWeight: 500,
                                width: 95,
                                height: 25,
                                bgcolor: "rgba(255, 249, 196, 0.8)",
                                color: "black",
                              }}
                              label={row?.status}
                            />
                          )}
                          {!["In Progress", "Completed"].includes(
                            row?.status
                          ) && (
                            <Chip
                              sx={{
                                fontWeight: 500,
                                width: 95,
                                height: 25,
                                bgcolor: "rgba(211, 47, 47, 0.4)",
                                color: "black",
                              }}
                              label={row?.status}
                            />
                          )}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {row?.status === "In Progress" && (
                            <Tooltip title="Stop">
                              <StopCircleIcon
                                onClick={() => handleOpenModal("stop", row)}
                                sx={{
                                  cursor: "pointer",
                                  color: "rgba(237, 108, 2, 1)",
                                }}
                              />
                            </Tooltip>
                          )}
                          {row?.status !== "In Progress" && (
                            <Tooltip title="Delete">
                              <DeleteOutlineIcon
                                onClick={() => handleOpenModal("delete", row)}
                                sx={{
                                  cursor: "pointer",
                                  color: "rgba(211, 47, 47, 1)",
                                }}
                              />
                            </Tooltip>
                          )}
                        </StyledTableCell>
                      </StyledTableRow>
                    ))
                  ) : (
                    <StyledTableRow>
                      <StyledTableCell colSpan={5} align="center">
                        <Typography
                          sx={{ fontSize: 18, fontWeight: "bold", py: 1 }}
                        >
                          {" "}
                          No Data Available
                        </Typography>
                      </StyledTableCell>
                    </StyledTableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5]}
              component="div"
              count={crons.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </>
        )}

        {/* <TableContainer>
            <Table
              size="small"
              sx={{ minWidth: 650 }}
              aria-label="simple table"
            >
              <TableHead>
                <TableRow sx={{ bgcolor: "#b5b5b5" }}>
                  <TableCell sx={{ border: "none", width: 300 }}>
                    <Typography align="left" sx={{ fontWeight: "medium" }}>
                      Module
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ border: "none" }}>
                    <Typography
                      align="left"
                      sx={{ ml: 2, fontWeight: "medium" }}
                    >
                      Status
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
               
                {crons.length > 0 ? (
                  crons.map((cron, index) => (
                    <TableRow
                      key={cron?.uuid || index}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                        bgcolor: "#F5F5F5",
                      }}
                    >
                      <TableCell
                        sx={{ border: "none", width: 300, height: 55 }}
                      >
                        <Typography>
                          {cron?.req_body?.module?.displayName}
                        </Typography>
                      </TableCell>
                      <TableCell
                        sx={{ border: "none", width: 300, height: 55 }}
                      >
                        <Typography>{cron?.status}</Typography>
                      </TableCell> */}
        {/* <TableCell
                        sx={{ border: "none", height: 55 }}
                      ></TableCell> */}
        {/* <TableCell sx={{ border: "none", height: 55 }}>
                        {!focus && (
                          <Box
                            onMouseOver={() => setMouseOver(true)}
                            onMouseOut={(event) => {
                              if (
                                textRef.current &&
                                textRef.current.contains(event.relatedTarget)
                              ) {
                                return;
                              } else {
                                setMouseOver((prev) => false);
                              }
                            }}
                            ref={textRef}
                            sx={{
                              "&:hover": {
                                border: "1px solid grey",
                                cursor: "pointer",
                              },
                              ml: 1,
                              py: 0.5,
                              px: 1,
                              borderRadius: "4px",
                              display: "flex",
                              justifyContent: "space-between",
                              width: "81%",
                            }}
                            onClick={() => setFocus(true)}
                          >
                            <Typography>
                              {myValueState.current?.display_name}
                            </Typography>
                            {mouseOver && (
                              <EditRoundedIcon
                                sx={{
                                  mr: 0.5,
                                  mt: 0.2,
                                  fontSize: 17,
                                }}
                              />
                            )}
                          </Box>
                        )}

                        {focus && (
                          <Box sx={{ display: "flex" }}>
                            <Autocomplete
                              disablePortal
                              clearText="{null}"
                              value={myValueState.current}
                              onChange={(event, newValue) => {
                                // ;
                                // setValue((prev) => newValue);
                                if (newValue) {
                                  setMyValueState({
                                    ...myValueState,
                                    current: newValue,
                                  });
                                  // setMyValueState(newValue);
                                } else {
                                  setMyValueState({
                                    ...myValueState,
                                    current: null,
                                  });
                                }
                                // else {
                                //   myValue.current = myValue.previous;
                                //   // setMyValueState(newValue);
                                // }
                                // setFieldApiName((prev) => newValue?.api_name);
                                // setRootFolderId(
                                //   (prev) => recordData?.[newValue?.api_name]
                                // );
                              }}
                              id="combo-box-demo"
                              options={fields}
                              size="small"
                              fullWidth
                              sx={{
                                // width: 250,
                                py: 1,
                                px: 1,
                                "& .MuiInputBase-input": {
                                  height: "1.2rem",
                                },
                                "& .MuiAutocomplete-popper": {
                                  backgroundColor: "red",
                                },
                              }}
                              // renderOption={(option) => (
                              //   <Typography style={{ fontSize: "1.5rem" }}>{option.label}</Typography>
                              // )}
                              getOptionLabel={(option) => option.display_name}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  // label="Choose the Folder ID"
                                  inputProps={{
                                    ...params.inputProps,
                                    style: { fontSize: 14 },
                                  }}
                                  sx={{
                                    "& .MuiFormLabel-root": {
                                      fontSize: "16px",
                                    },
                                  }}
                                />
                              )}
                            />
                            {myValueState.current ? (
                              <CheckRoundedIcon
                                onClick={async () => {
                                  // upsertData(data: {} | undefined, connname: any, orgid: any, apikey: any, settingUrl?: string | undefined): Promise<any>

                                  const settingData = {
                                    settingId:
                                      moduleRelatedList[index]?.setting_id,
                                    settingSchema: {
                                      moduleApiName:
                                        moduleRelatedList[index]?.module_name,
                                      fieldApiName: {
                                        display_name:
                                          myValueState.current?.display_name,
                                        api_name:
                                          myValueState.current?.api_name,
                                      },
                                      status: true,
                                    },
                                  };

                                  // settingData = {
                                  //   settingId: "97f9efbf-d36b-4142-962a-1921a101e2b5",
                                  //   settingSchema: {
                                  //     moduleApiName: "Contacts",
                                  //     fieldApiName: {
                                  //       display_name: "Last Name",
                                  //       api_name: "Last_Name"
                                  //     },
                                  //     status: true,
                                  //   },
                                  // };

                                  const upsertData =
                                    await ApiCalling.upsertData(
                                      settingData,
                                      "easysharepointforcrm",
                                      orgid,
                                      apikey,
                                      datacenterurl
                                    );

                                  if (upsertData?.error) {
                                    return "";
                                  }

                                  const tempSettingsArray = moduleRelatedList;
                                  tempSettingsArray[index] = {
                                    ...moduleRelatedList[index],
                                    setting_schema: JSON.stringify({
                                      moduleApiName:
                                        moduleRelatedList[index]?.module_name,
                                      fieldApiName: {
                                        display_name:
                                          myValueState.current?.display_name,
                                        api_name:
                                          myValueState.current?.api_name,
                                      },
                                      status: true,
                                    }),
                                    fieldName:
                                      myValueState.current?.display_name,
                                    moduleApiName:
                                      myValueState.current?.api_name,
                                  };

                                  setEditData((prev) => {});
                                  setModuleRelatedList(
                                    (prev) => tempSettingsArray
                                  );

                                  setMyValueState({
                                    ...myValueState,
                                    prev: myValueState.current,
                                  });
                                  setMouseOver((prev) => false);
                                  setFocus((prev) => false);
                                }}
                                // onClick={() =>{}}
                                sx={{
                                  mt: 1.7,
                                  mx: 0.5,
                                  p: 0.1,
                                  fontSize: 20,
                                  // fontWeight: 'bold',
                                  bgcolor: "#1e88e6",
                                  borderRadius: "50%",
                                  color: "#ffffff",
                                  "&:hover": { cursor: "pointer" },
                                }}
                              />
                            ) : (
                              <CheckRoundedIcon
                                sx={{
                                  mt: 1.7,
                                  mx: 0.5,
                                  p: 0.1,
                                  fontSize: 20,
                                  // fontWeight: 'bold',
                                  bgcolor: "#9e9e9e",
                                  borderRadius: "50%",
                                  color: "#ffffff",
                                  // "&:hover": { cursor: "pointer" },
                                }}
                              />
                            )}

                            <HighlightOffIcon
                              onClick={() => {
                                setMyValueState({
                                  ...myValueState,
                                  current: myValueState.prev,
                                });
                                setFocus((prev) => false);
                                setMouseOver((prev) => false);
                              }}
                              sx={{
                                mt: 1.7,
                                color: "#c91228",
                                "&:hover": { cursor: "pointer" },
                              }}
                            />
                          </Box>
                        )}
                      </TableCell> */}
        {/* </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell
                      colSpan={2}
                      align="center"
                      sx={{ border: "none", height: 200 }}
                    >
                      <CircularProgress size={28} />
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer> */}
      </Box>

      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            sx={{
              borderRadius: 5,
              bgcolor: "white",
              width: 500,
              p: 4,
              height: 140,
            }}
          >
            {" "}
            <Typography sx={{ mt: 4, fontWeight: "bold", fontSize: 20 }}>
              Do you want to{" "}
              {individualCron.type === "stop" ? "stop" : "delete"} this log?
            </Typography>
            <Box
              sx={{
                mt: 3,
                ml: "1rem",
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
                gap: "1rem",
              }}
            >
              <Button
                sx={{ width: 120 }}
                variant="outlined"
                onClick={handleCloseModal}
              >
                Cancel
              </Button>

              <Button
                disabled={loading}
                sx={{ width: 120 }}
                variant="contained"
                onClick={handleCronUpdate}
              >
                Proceed
                {loading && (
                  <CircularProgress sx={{ color: "white", ml: 1 }} size={16} />
                )}
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>

      <Modal
        open={isOpenCreateCronModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <ProcessHistoricData
            zapikey={zapiKey}
            previousModule={[]}
            toggle={toggle}
            setToggle={setToggle}
            inProgressModuleList={inProgressModuleList}
            orgid={orgid}
            connname={connname}
            apikey={apikey}
            datacenterurl={datacenterurl}
            zuid={zuid}
            handleClickOpen={handleClickOpen}
            handleClose={handleClose}
          />
        </Box>
      </Modal>
    </Box>
  );
}

export default CronLIst;
