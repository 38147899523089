import React, { useEffect, useState } from "react";

const ZOHO = window.ZOHO;

function useField({ Entity = "" }) {
  const [admin, setAdmin] = useState(false);
  const [fields, setFields] = useState([]);

  // Fetch fetchRecordData
  useEffect(() => {
    async function fetchRecordData(params) {
      try {
        const userDetails = await ZOHO.CRM.CONFIG.getCurrentUser();
        const user = userDetails?.users?.[0];
        if (user?.profile?.name == "Administrator") {
          setAdmin((prev) => true);
        }
        const fieldDetails = await ZOHO.CRM.META.getFields({ Entity: Entity });
        const moduleFields = fieldDetails?.fields
          ?.filter((field) => {
            return (
              field?.json_type == "string" &&
              field?.data_type == "text" &&
              field?.read_only == false
            );
          })
          .map((field) => {
            return {
              display_name: field?.field_label,
              api_name: field?.api_name,
            };
          });

        setFields((prev) => moduleFields);
      } catch (error) {}
    }
    if (Entity != "") {
      fetchRecordData();
    }
  }, [Entity]);

  return { admin, fields };
}

export default useField;
