import React, { useState, useRef, useEffect } from "react";
import SortableTree, {
  addNodeUnderParent,
  removeNodeAtPath,
  getNodeAtPath,
  changeNodeAtPath,
} from "@nosferatu500/react-sortable-tree";
import Popover from "@mui/material/Popover";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import getCaretCoordinates from "../../Others/DynamicHashTextField/Functions/getCaretCorordinate";
// import { useSnapshot } from "valtio";
// import { widState } from "../../../Store/workdriveState";

export default function SelectFromModuleFields({
  moduleFields,
  node,
  path,
  getNodeKey,
  myTreeData,
  savedData,
}) {
  // const snap = useSnapshot(widState)
  const [anchorEl, setAnchorEl] = useState(null);
  const [top, setTop] = useState(0);
  const [left, setLeft] = useState(0);
  const [textareaValue, setTextAreaValue] = useState(node?.title || "");

  const popOverRef = useRef();
  const textFieldRef = useRef();
  const autoCompleteRef = useRef();

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    // // ;
    // setTreeData(
    //   changeNodeAtPath({
    //     treeData: treeData,
    //     path,
    //     getNodeKey,
    //     newNode: { ...node, title: textareaValue },
    //   })
    // );
    setAnchorEl(null);
  };

  useEffect(() => {
    setTextAreaValue(node.title);
  }, [node?.title]);

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <>
      <TextField
        id="outlined-multiline-static"
        placeholder="Select Fields"
        variant="standard"
        InputProps={{ disableUnderline: true }}
        sx={{ width: "300px" }}
        aria-describedby={id}
        inputRef={textFieldRef}
        value={textareaValue.replace("#", "")}
        onChange={(e) => {
          setTextAreaValue(e.target.value);
          savedData({
            key: "treeData",
            response: changeNodeAtPath({
              treeData: myTreeData,
              path,
              getNodeKey,
              newNode: { ...node, title: e.target.value },
            }),
          });
        }}
        onKeyPress={(event) => {
          const grabKeycodeOfLastLetter = event.which || event.keyCode;
          if (grabKeycodeOfLastLetter === 35) {
            handlePopoverOpen(event);

            //We need to delay to open the popover in order order to calculate the width of the popover
            setTimeout(() => {
              var coordinates = getCaretCoordinates(
                event.target,
                event.target.selectionEnd,
                {
                  debug: true,
                }
              );
              const windowouterWidth = window.outerWidth;

              let popOverWidth =
                popOverRef.current.querySelector(
                  ".MuiPopover-paper"
                ).clientWidth;

              //popOver position
              let left;
              let top =
                (
                  document.documentElement ||
                  document.body.parentNode ||
                  document.body
                ).scrollTop + coordinates.top;
              const isThereRoomForPopoverWidth =
                windowouterWidth > popOverWidth;

              // is there space to show popover then show popover next to the letter otherwise deduct the popover width from left(const)
              if (isThereRoomForPopoverWidth) {
                left =
                  event.target.offsetLeft -
                  event.target.scrollLeft +
                  coordinates.left +
                  14;
              } else {
                left =
                  event.target.offsetLeft -
                  event.target.scrollLeft +
                  coordinates.left +
                  14 -
                  popOverWidth;
              }
              autoCompleteRef.current.focus();

              setTop(top);
              setLeft(left);
            }, 10);
          }
        }}
      />

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        ref={popOverRef}
        style={{
          position: "absolute",
          top: `${
            window.pageYOffset + 40 ||
            (
              document.documentElement ||
              document.body.parentNode ||
              document.body
            ).scrollTop + 40
          }px`,
          left: `${left - 10}px`,
          padding: "20px",
          height: "100%",
        }}
      >
        <Autocomplete
          autoHighlight={true}
          size="small"
          options={moduleFields}
          groupBy={(option) => option?.lookupDisplayLabel}
          getOptionLabel={(option) => option?.fieldDisplayLabel}
          sx={{ width: 300, padding: "10px" }}
          disableClearable={true}
          onChange={(e, value) => {
            // ;
            // setSelectedField(e.target.textContent);

            var start = textFieldRef.current.selectionStart,
              end = textFieldRef.current.selectionEnd;


            value.lookupApiName
              ? setTextAreaValue(
                  textareaValue.slice(0, start) +
                    "${" +
                    value.lookupApiName +
                    "." +
                    value.fieldApiName +
                    "}" +
                    textareaValue.slice(end)
                )
              : setTextAreaValue(
                  textareaValue.slice(0, start) +
                    "${" +
                    value.fieldApiName +
                    "}" +
                    textareaValue.slice(end)
                );

            // value.lookupApiName
            //   ? setTreeData(
            //       changeNodeAtPath({
            //         treeData: treeData,
            //         path,
            //         getNodeKey,
            //         newNode: {
            //           ...node,
            //           title:
            //             textareaValue.slice(0, start) +
            //             "${" +
            //             value.lookupApiName +
            //             "." +
            //             value.fieldApiName +
            //             "}" +
            //             textareaValue.slice(end),
            //         },
            //       })
            //     )
            //   : setTreeData(
            //       changeNodeAtPath({
            //         treeData: treeData,
            //         path,
            //         getNodeKey,
            //         newNode: {
            //           ...node,
            //           title:
            //             textareaValue.slice(0, start) +
            //             "${" +
            //             value.fieldApiName +
            //             "}" +
            //             textareaValue.slice(end),
            //         },
            //       })
            //     );

            value.lookupApiName
              ? savedData({
                  key: "treeData",
                  response: changeNodeAtPath({
                    treeData: myTreeData,
                    path,
                    getNodeKey,
                    newNode: {
                      ...node,
                      title:
                        textareaValue.slice(0, start) +
                        "${" +
                        value.lookupApiName +
                        "." +
                        value.fieldApiName +
                        "}" +
                        textareaValue.slice(end),
                    },
                  }),
                })
              : savedData({
                  key: "treeData",
                  response: changeNodeAtPath({
                    treeData: myTreeData,
                    path,
                    getNodeKey,
                    newNode: {
                      ...node,
                      title:
                        textareaValue.slice(0, start) +
                        "${" +
                        value.fieldApiName +
                        "}" +
                        textareaValue.slice(end),
                    },
                  }),
                });
            handlePopoverClose();
            return;
          }}
          renderInput={(params) => (
            <TextField
              inputRef={autoCompleteRef}
              {...params}
              label="Select Fields"
            />
          )}
        />
      </Popover>
    </>
  );
}
