import React, { useEffect } from "react";

import Modal from "@mui/material/Modal";
import { Box } from "@mui/system";

import {
  CardActionArea,
  CardActions,
  CardMedia,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import Button from "@mui/material/Button";
import { Card } from "react-bootstrap";
import SvgIcon from "@mui/material/SvgIcon";
import IconButton from "@mui/material/IconButton";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import CancelIcon from "@mui/icons-material/Cancel";
import FilePresentIcon from "@mui/icons-material/FilePresent";
import FilePresent from "@mui/icons-material/FilePresent";
import { useSnapshot } from "valtio";
import { widState } from "../../../store/workdriveState";
import "./ModalFileUpload.css";
// import { CardActionArea } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  maxWidth: "1100px",
  width: "60%",
  height: "auto",
  padding: 5,
  transform: "translate(-50%, -50%)",
  outline: "none",
  background: "#ffffff",
  display: "flex",
  flexDirection: "column",
  flexWrap: "wrap",
  justifyContent: "center",
};

const commonStyles = {
  borderColor: "#0FEFEF",
  m: 1,
  border: 2,
  width: "5rem",
  height: "5rem",
};

function ModalFileUpload({
  files,
  removeFile,
  open,
  setOpen,
  handleCloseModal,
  uploadFile,
}) {
  const snap = useSnapshot(widState);

  const handleClose = () => {
    // // ;
    snap?.setLoading(false);
    setOpen(false);
  };

  useEffect(() => {
    // // ;
  }, []);

  return (
    <Modal
      open={open}
      onClose={handleCloseModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className="modal-overlay">
        <div className="modal-content">
          <div className="modal-body">
            {/* <Box
              sx={{
                ...commonStyles,
                borderRadius: "16px",
                ...style,
                borderColor: "grey.500",
              }}
            >
              <Box sx={{ width: "100%", maxWidth: 500 }}>
                <Typography variant="h6" component="div" gutterBottom>
                  Upload File
                </Typography>
              </Box>

              <Grid container direction="row">
                {files.map((file) => (
                  <Card sx={{ maxWidth: 200 }}>
                    <CardActionArea>
                      {file.specialType != "image" ? (
                        <FilePresentIcon sx={{ width: 200, height: 140 }} />
                      ) : (
                        <CardMedia
                          component="img"
                          height="140"
                          width="200"
                          image={file.preview}
                          alt="green iguana"
                        />
                      )}
                      <IconButton
                        sx={{ color: "black" }}
                        aria-label={`star`}
                        onClick={() => {
                          removeFile(file);
                        }}
                        size="large"
                      >
                        <CancelIcon sx={{ right: 0, top: 0 }} />
                      </IconButton>
                      <Typography
                        variant="body1"
                        gutterBottom
                        sx={{
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {file?.name}
                      </Typography>
                    </CardActionArea>
                  </Card>
                ))}
              </Grid>

              <Stack direction="row" spacing={2}>
                <Button variant="outlined" onClick={handleCloseModal}>
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  onClick={() => {
                    uploadFile();
                  }}
                >
                  Upload
                </Button>
              </Stack>
            </Box> */}
            <Box sx={{ width: "100%", maxWidth: 500 }}>
              <Typography variant="h6" component="div" gutterBottom>
                Upload File
              </Typography>
            </Box>

            <Grid container direction="row" spacing={4}>
              {files.map((file) => (
                <Grid item>
                  <Card sx={{ maxWidth: 200 }}>
                    <CardActionArea>
                      {file.specialType != "image" ? (
                        <FilePresentIcon sx={{ width: 200, height: 140 }} />
                      ) : (
                        <CardMedia
                          component="img"
                          height="140"
                          width="200"
                          image={file.preview}
                          alt="green iguana"
                        />
                      )}
                      <IconButton
                        sx={{ color: "black" }}
                        aria-label={`star`}
                        onClick={() => {
                          removeFile(file);
                        }}
                        size="large"
                      >
                        <CancelIcon sx={{ right: 0, top: 0 }} />
                      </IconButton>
                      <Typography
                        variant="body1"
                        gutterBottom
                        sx={{
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {file?.name}
                      </Typography>
                    </CardActionArea>
                  </Card>
                </Grid>
              ))}
            </Grid>

            <Stack direction="row" spacing={2}>
              <Button variant="outlined" onClick={handleCloseModal}>
                Cancel
              </Button>
              <Button
                variant="contained"
                onClick={() => {
                  uploadFile();
                }}
              >
                Upload
              </Button>
            </Stack>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default ModalFileUpload;
