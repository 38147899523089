import {
  Box,
  CircularProgress,
  Divider,
  Snackbar,
  Typography,
} from "@mui/material";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import { useEffect, useState } from "react";
import Overview from "../Components/Overview/Overview";
import SettingsPage from "../Components/SettingsPage/SettingsPage";
import sharePointLogo from "../Images/sharepointlogo.png";
import axios from "axios";
import { useSnapshot } from "valtio";
import { widState } from "../Store/workdriveState";
import logo from "../Images/easypluginz-label.png";
import FAQ from "../Components/FAQ/FAQ";
import ModulesRelatedFieldSetting from "../Components/ModuleRelatedSetting/ModulesRelatedFieldSetting";

const ZOHO = window.ZOHO;

export default function Settings() {
  const snap = useSnapshot(widState);
  const [loading, setLoading] = useState(true);
  const [authLoadingZoho, setAuthLoadingZoho] = useState(false);
  const [authLoadingDropbox, setAuthLoadingDropbox] = useState(false);
  const [revokeLoadingZoho, setRevokeLoadingZoho] = useState(false);
  const [revokeLoadingDropbox, setRevokeLoadingDropbox] = useState(false);
  const [auth, setAuth] = useState("Active");
  const [settings, setSettings] = useState("Inactive");
  const [zohoAuth, setZohoAuth] = useState(false);
  const [dropboxAuth, setDropboxAuth] = useState(false);
  const [faq, setFaq] = useState("Inactive");
  const [moduleRelatedSetting, setModuleRelatedSetting] = useState("Inactive");
  const [apiKey, setApiKey] = useState();
  const [orgId, setOrgId] = useState();
  const [templateId, setTemplateId] = useState();
  const [zapiKey, setZapiKey] = useState();
  const [dataCenterUrl, setDataCenterUrl] = useState("");
  const [zuid, setZuid] = useState(null);

  const [zohoLoaded, setZohoLoaded] = useState(false);

  useEffect(() => {
    async function initZoho() {
      ZOHO.embeddedApp.on("PageLoad", async function (data) {
        const currentEnv = await ZOHO.CRM.CONFIG.GetCurrentEnvironment();
        const dataCenterMap = {
          US: "https://www.zohoapis.com",
          EU: "https://www.zohoapis.eu",
          AU: "https://www.zohoapis.com.au",
          IN: "https://www.zohoapis.in",
          China: "https://www.zohoapis.com.cn",
          JP: "https://www.zohoapis.jp",
        };

        const dataCenterData = {
          apiname: "easysharepointforcrm__Data_Center_URL",
          value: dataCenterMap?.[currentEnv?.deployment] || "",
        };
        await ZOHO.CRM.CONNECTOR.invokeAPI("crm.set", dataCenterData);

        setDataCenterUrl(dataCenterMap?.[currentEnv?.deployment] || "");
        setZohoLoaded(true);
      });

      ZOHO.embeddedApp.init();
    }
    initZoho();
  }, []);

  const handleCreateDropboxTemplate = async ({ headers }) => {
    try {
      if (templateId) {
        return true;
      }
      const templateResp = await axios({
        url: `${process.env.REACT_APP_API_SERVER_URL}/sharepoint/template`,
        method: "get",
        headers: headers,
      });

      const newTemplateId = {
        apiname: "easysharepointforcrm__Template_ID",
        value: templateResp?.data?.data?.template_id || "",
      };

      if (templateResp?.data?.data) {
        setTemplateId((prev) => templateResp?.data?.data?.template_id);
        await ZOHO.CRM.CONNECTOR.invokeAPI("crm.set", newTemplateId);
        return true;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  };

  const handleAuthenticateZoho = async () => {
    setAuthLoadingZoho(true);

    const authUrlResp = await axios.request({
      url: process.env.REACT_APP_ADMIN_SERVER_URL + "/auth/zoho/authenticate",
      method: "POST",
      data: {
        orgId: orgId,
        apiKey: apiKey,
        zapiKey: zapiKey,
        accountsUrl:
          "https://accounts.zoho." +
          dataCenterUrl?.split("https://www.zohoapis.")?.[1],
        connName: "easysharepointforcrm__zoho",
        scope: [
          "ZohoCRM.users.CREATE",
          "ZohoCRM.users.READ",
          "ZohoCRM.users.UPDATE",
          "ZohoCRM.org.READ",
          "ZohoCRM.org.UPDATE",
          "ZohoCRM.settings.CREATE",
          "ZohoCRM.settings.READ",
          "ZohoCRM.settings.UPDATE",
          "ZohoCRM.coql.READ",
          "ZohoCRM.modules.CREATE",
          "ZohoCRM.modules.READ",
          "ZohoCRM.modules.UPDATE",
        ],
        redirectUrl: `${process.env.REACT_APP_ADMIN_SERVER_URL}/auth/zoho/callback`,
        crmApiDomain: dataCenterUrl,
        zuid: zuid,
      },
    });

    const myAuthCheckInterval = setInterval(async () => {
      const authCheckStatus = await handleAuthCheckZoho({
        headers: {
          apikey: apiKey,
          connname: "easysharepointforcrm__zoho",
          orgid: orgId,
          accountsurl: dataCenterUrl,
          dataCenterurlvariablename: "easysharepointforcrm__Data_Center_URL",
        },
        dataCenterUrl: dataCenterUrl,
      });

      if (authCheckStatus) {
        setZohoAuth(true);
        setAuthLoadingZoho(false);
        if (dropboxAuth) {
          setSettings("Active");
          setAuth("Successful");
          clearInterval(myAuthCheckInterval);
        }
      } else {
        setSettings("Inactive");
        setAuth("Active");
      }
    }, 12000);

    const newwindow = window.open(
      authUrlResp?.data?.authUrl,
      "windowName",
      "height=640,width=1024"
    );

    if (window.focus) {
      newwindow.focus();
    }
  };

  const handleAuthenticateDropbox = async () => {
    setAuthLoadingDropbox((prev) => true);

    try {
      const authUrlResp = await axios.request({
        url:
          process.env.REACT_APP_ADMIN_SERVER_URL +
          "/auth/sharepoint/authenticate",
        method: "POST",
        data: {
          orgId: orgId,
          apiKey: apiKey,
          zapiKey: zapiKey,
          connName: "easysharepointforcrm__sharepoint",
          authType: "OAuth2",
          scope: [
            "Files.ReadWrite.All",
            "User.Read",
            "Sites.ReadWrite.All",
            "offline_access",
          ],
          redirectUrl: `${process.env.REACT_APP_ADMIN_SERVER_URL}/auth/sharepoint/callback`,
        },
      });

      const myAuthCheckInterval = setInterval(async () => {
        const authCheckStatus = await handleAuthCheckDropbox({
          headers: {
            apikey: apiKey,
            connname: "easysharepointforcrm__sharepoint",
            orgid: orgId,
            services: "drive",
          },
        });

        if (authCheckStatus) {
          setDropboxAuth((prev) => true);
          setAuthLoadingDropbox((prev) => false);
          if (zohoAuth) {
            setSettings((prev) => "Active");
            setAuth((prev) => "Successful");
            const createDropboxTempleteZCRM = await handleCreateDropboxTemplate(
              {
                headers: {
                  apikey: apiKey,
                  connname: "easysharepointforcrm__sharepoint",
                  orgid: orgId,
                  services: "drive",
                },
              }
            );

            if (createDropboxTempleteZCRM) {
              clearInterval(myAuthCheckInterval);
            } else {
              clearInterval(myAuthCheckInterval);
            }
          }
        }
      }, 12000);

      const newwindow = window.open(
        authUrlResp?.data?.authUrl,
        "windowName",
        "height=640,width=1024"
      );

      if (window.focus) {
        newwindow.focus();
      }
    } catch (error) {
      setAuthLoadingDropbox((prev) => false);
    }
  };

  const handleAuthCheckZoho = async ({ headers, dataCenterUrl }) => {
    // for zoho auth verify
    try {
      const authCheck = await axios({
        url: `${process.env.REACT_APP_ADMIN_SERVER_URL}/auth/zoho/verifyauth`,
        headers: headers,
      });
      if (authCheck?.data?.org?.length > 0) {
        /**
         * Create Default Widget
         */
        // if (dataCenterUrl) {

        try {
          const defaultFolderConfig = {
            url: `${process.env.REACT_APP_API_SERVER_URL}/extfolders`,
            method: "POST",
            data: {},
            headers: {
              ...headers,
              connname: "easysharepointforcrm__sharepoint",
              "content-Type": "application/json",
              datacenterurl: dataCenterUrl,
            },
          };
          const defaultFoldersCreated = await axios.request(
            defaultFolderConfig
          );
          if (defaultFoldersCreated?.data?.error) {
            return false;
          }
          return true;
        } catch (error) {
          return false;
        }

        // const createExtension = await axios.request({
        //   url: `${process.env.REACT_APP_ADMIN_SERVER_URL}/data/zoho/crm/extwidgets`,
        //   method: "POST",
        //   data: {
        //     widgetName: "Dropbox by Easy Pluginz",
        //     widgetUrl:
        //       "https://widgets.v1.easysharepointforcrm__sharepoint.easy-pluginz.com/relatedlist",
        //     widgetDescription:
        //       "This widget you can utilizet in any Standard Module and Custom Module to visualize, crate, edit, delete folders and to crete shared links",
        //     widgetType: "RELATED_LIST",
        //     apiDomain: dataCenterUrl,
        //   },
        //   headers: {
        //     apikey: headers?.apikey,
        //     connname: "easysharepointforcrm__zoho",
        //     datacenterurl: dataCenterUrl,
        //     orgid: headers?.orgid,
        //   },
        // });
        // }

        // Create Default Settings
        // const createSettings = await axios.request({
        //   url: `${process.env.REACT_APP_API_SERVER_URL}/sharepoint/extrelatedsettings`,
        //   method: "GET",
        //   headers: {
        //     apikey: headers?.apikey,
        //     datacenterurl: dataCenterUrl,
        //     connname: "easysharepointforcrm__sharepoint",
        //     orgid: headers?.orgid,
        //   },
        // });
        // return true;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  };

  const handleAuthCheckDropbox = async ({ headers }) => {
    try {
      const authCheck = await axios({
        url: `${process.env.REACT_APP_ADMIN_SERVER_URL}/auth/sharepoint/verifyauth`,
        method: "get",
        headers: headers,
      });
      if (authCheck?.data?.data) {
        /**
         * Create Default Widget
         */
        if (dataCenterUrl) {
          const createExtension = await axios.request({
            url: `${process.env.REACT_APP_ADMIN_SERVER_URL}/data/zoho/crm/extwidgets`,
            method: "POST",
            data: {
              widgetName: "Sharepoint by Easy Pluginz",
              widgetUrl:
                "https://widgets.v2.easysharepointforcrm.easy-pluginz.com/relatedlist",
              widgetDescription:
                "This widget you can utilizet in any Standard Module and Custom Module to visualize, crate, edit, delete folders and to crete shared links",
              widgetType: "RELATED_LIST",
              apiDomain: dataCenterUrl,
            },
            headers: {
              apikey: headers?.apikey,
              connname: "easysharepointforcrm__zoho",
              datacenterurl: dataCenterUrl,
              orgid: headers?.orgid,
            },
          });
        }
        // Create Default Settings
        const createSettings = await axios.request({
          url: `${process.env.REACT_APP_API_SERVER_URL}/extrelatedsettings`,
          method: "GET",
          headers: {
            apikey: headers?.apikey,
            datacenterurl: dataCenterUrl,
            connname: "easysharepointforcrm",
            orgid: headers?.orgid,
          },
        });

        return true;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  };

  const handleRevokeDropbox = async () => {
    setRevokeLoadingDropbox(true);
    const authUrlResp = await axios.request({
      url: process.env.REACT_APP_ADMIN_SERVER_URL + "/auth/sharepoint/revoke",
      method: "GET",
      headers: {
        orgid: orgId,
        apikey: apiKey,
        connname: "easysharepointforcrm__sharepoint",
      },
    });
    if (authUrlResp?.data) {
      // ;
      setRevokeLoadingDropbox(false);
      setDropboxAuth(false);
    } else {
      setRevokeLoadingDropbox(false);
    }
  };

  const handleRevokeZoho = async () => {
    setRevokeLoadingZoho(true);
    const authUrlResp = await axios.request({
      url: process.env.REACT_APP_ADMIN_SERVER_URL + "/auth/zoho/revoke",
      method: "GET",
      headers: {
        orgid: orgId,
        apikey: apiKey,
        connname: "easysharepointforcrm__zoho",
      },
    });
    if (authUrlResp?.data) {
      setZohoAuth(false);
      setRevokeLoadingZoho(false);
    } else {
      setRevokeLoadingZoho(false);
    }
  };

  const fetchOrgVariablesData = async () => {
    /**
     * Get Organization Variables
     */
    const orgData = {
      apiKeys: [
        "easysharepointforcrm__API_KEY",
        "easysharepointforcrm__Organization_ID",
        "easysharepointforcrm__ZAPI_Key",
        "easysharepointforcrm__Data_Center_URL",
        "easysharepointforcrm__Template_ID",
      ],
    };
    const orgVariables = await ZOHO.CRM.API.getOrgVariable(orgData);

    setDataCenterUrl(
      orgVariables?.Success?.Content?.easysharepointforcrm__Data_Center_URL
        ?.value
        ? orgVariables?.Success?.Content?.easysharepointforcrm__Data_Center_URL
            ?.value != "null"
          ? orgVariables?.Success?.Content
              ?.easysharepointforcrm__Data_Center_URL?.value
          : dataCenterUrl
        : dataCenterUrl
    );
    widState.dataCenterUrl = orgVariables?.Success?.Content
      ?.easysharepointforcrm__Data_Center_URL?.value
      ? orgVariables?.Success?.Content?.easysharepointforcrm__Data_Center_URL
          ?.value != "null"
        ? orgVariables?.Success?.Content?.easysharepointforcrm__Data_Center_URL
            ?.value
        : dataCenterUrl
      : dataCenterUrl;
    setApiKey(
      orgVariables?.Success?.Content?.easysharepointforcrm__API_KEY?.value
    );
    widState.apiKey =
      orgVariables?.Success?.Content?.easysharepointforcrm__API_KEY?.value;
    setOrgId(
      orgVariables?.Success?.Content?.easysharepointforcrm__Organization_ID
        ?.value
    );
    widState.orgId =
      orgVariables?.Success?.Content?.easysharepointforcrm__Organization_ID?.value;
    setTemplateId(
      orgVariables?.Success?.Content?.easysharepointforcrm__Template_ID?.value
    );
    widState.templateId =
      orgVariables?.Success?.Content?.easysharepointforcrm__Template_ID?.value;
    setZapiKey(
      orgVariables?.Success?.Content?.easysharepointforcrm__ZAPI_Key?.value
    );
    widState.zapiKey =
      orgVariables?.Success?.Content?.easysharepointforcrm__ZAPI_Key?.value;

    const headersZoho = {
      apikey:
        orgVariables?.Success?.Content?.easysharepointforcrm__API_KEY?.value,
      connname: "easysharepointforcrm__zoho",
      orgid:
        orgVariables?.Success?.Content?.easysharepointforcrm__Organization_ID
          ?.value,
      accountsurl: orgVariables?.Success?.Content
        ?.easysharepointforcrm__Data_Center_URL?.value
        ? orgVariables?.Success?.Content?.easysharepointforcrm__Data_Center_URL
            ?.value != "null"
          ? orgVariables?.Success?.Content
              ?.easysharepointforcrm__Data_Center_URL?.value
          : dataCenterUrl
        : dataCenterUrl,
      dataCenterurlvariablename: "easysharepointforcrm__Data_Center_URL",
    };

    const headersDropbox = {
      apikey:
        orgVariables?.Success?.Content?.easysharepointforcrm__API_KEY?.value,
      connname: "easysharepointforcrm__sharepoint",
      orgid:
        orgVariables?.Success?.Content?.easysharepointforcrm__Organization_ID
          ?.value,
      services: "drive",
    };
    // ;
    /**
     * Fetch User Details
     */
    const orgResp = await ZOHO.CRM.CONFIG.getOrgInfo();

    setZuid(orgResp?.org?.[0]?.primary_zuid);
    widState.zuid = orgResp?.org?.[0]?.primary_zuid;

    const authCheckStatusZoho = await handleAuthCheckZoho({
      headers: headersZoho,
      dataCenterUrl: orgVariables?.Success?.Content
        ?.easysharepointforcrm__Data_Center_URL?.value
        ? orgVariables?.Success?.Content?.easysharepointforcrm__Data_Center_URL
            ?.value != "null"
          ? orgVariables?.Success?.Content
              ?.easysharepointforcrm__Data_Center_URL?.value
          : dataCenterUrl
        : dataCenterUrl,
    });

    const authCheckStatusDropbox = await handleAuthCheckDropbox({
      headers: headersDropbox,
    });

    if (authCheckStatusZoho && authCheckStatusDropbox) {
      setSettings("Active");
      setAuth("Successful");
      setZohoAuth((prev) => true);
      setDropboxAuth((prev) => true);
    } else {
      authCheckStatusDropbox && setDropboxAuth((prev) => true);
      authCheckStatusZoho && setZohoAuth((prev) => true);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (zohoLoaded) {
      fetchOrgVariablesData();
    }
  }, [zohoLoaded]);

  if (!zohoLoaded) {
    return (
      <Box
        sx={{
          p: 2,
        }}
      >
        Data Not loaded yet.
      </Box>
    );
  }

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Typography sx={{ fontWeight: "bold" }}>
          Please Wait While Fetching Data...{" "}
        </Typography>
        <CircularProgress sx={{ mt: 1 }} size={26} />
      </Box>
    );
  } else {
    return (
      <Box
        sx={{
          bgcolor: "#F6F8FB",
          minHeight: "100vh",
          height: "100%",
          overflow: "auto",
        }}
      >
        <Box
          sx={{
            py: "12px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ marginLeft: "160px" }}>
            <img src={sharePointLogo} width={154} alt="Dropbox to crm logo" />
          </Box>
        </Box>
        <Divider />
        <Box
          sx={{
            mt: 4,
            mb: 1,
            display: "flex",
            justifyContent: "center",
          }}
        >
          {auth === "Active" ? (
            <Box
              sx={{
                textTransform: "none",
                border: "none",
                fontWeight: "600",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                paddingBottom: "5px",
                color: "#1976D2",
                width: 200,
                borderBottom: "3px solid #1976D2",
              }}
            >
              Authentication
            </Box>
          ) : (
            <Box
              onClick={() => {
                setFaq((prev) => "Inactive");
                setModuleRelatedSetting((prev) => "Inactive");
                setAuth((prev) => "Active");
                setSettings((prev) => "Inactive");
              }}
              sx={{
                textTransform: "none",
                border: "none",
                fontWeight: "600",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                paddingBottom: "5px",
                cursor: "pointer",
                color: "rgba(0, 0, 0, 0.38)",
                width: 200,
              }}
            >
              Authenticated
            </Box>
          )}
          {settings === "Active" ? (
            <Box
              variant="outlined"
              sx={{
                textTransform: "none",
                border: "none",
                fontWeight: "600",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                paddingBottom: "5px",
                color: "#1976D2",
                width: 200,
                borderBottom: "3px solid #1976D2",
              }}
            >
              {" "}
              Module Settings
            </Box>
          ) : (
            <Box
              onClick={() => {
                setFaq((prev) => "Inactive");
                setAuth((prev) => "Inactive");
                setModuleRelatedSetting((prev) => "Inactive");
                setSettings((prev) => "Active");
              }}
              sx={{
                textTransform: "none",
                border: "none",
                fontWeight: "600",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                paddingBottom: "5px",
                width: 200,
                cursor: "pointer",
                color: "rgba(0, 0, 0, 0.38)",
              }}
            >
              Module Settings
            </Box>
          )}
          {moduleRelatedSetting === "Active" ? (
            <Box
              variant="outlined"
              sx={{
                textTransform: "none",
                border: "none",
                fontWeight: "600",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                paddingBottom: "5px",
                color: "#1976D2",
                width: 200,
                borderBottom: "3px solid #1976D2",
              }}
            >
              {" "}
              Related List Settings
            </Box>
          ) : (
            <Box
              onClick={() => {
                setModuleRelatedSetting((prev) => "Active");
                setFaq((prev) => "Inactive");
                setAuth((prev) => "Inactive");
                setSettings((prev) => "Inactive");
              }}
              sx={{
                textTransform: "none",
                border: "none",
                fontWeight: "600",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                paddingBottom: "5px",
                width: 200,
                cursor: "pointer",
                color: "rgba(0, 0, 0, 0.38)",
              }}
            >
              Related List Settings
            </Box>
          )}
        </Box>

        {auth === "Active" && (
          <Overview
            handleRevokeDropbox={handleRevokeDropbox}
            handleRevokeZoho={handleRevokeZoho}
            authLoadingZoho={authLoadingZoho}
            authLoadingDropbox={authLoadingDropbox}
            revokeLoadingZoho={revokeLoadingZoho}
            revokeLoadingDropbox={revokeLoadingDropbox}
            zohoAuth={zohoAuth}
            dropboxAuth={dropboxAuth}
            handleAuthenticateZoho={handleAuthenticateZoho}
            handleAuthenticateDropbox={handleAuthenticateDropbox}
            dataCenterUrl={dataCenterUrl}
          />
        )}

        {settings === "Active" && <SettingsPage />}
        {moduleRelatedSetting === "Active" && <ModulesRelatedFieldSetting />}
        {faq === "Active" && <FAQ />}
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            mr: 2,
            my: 2,
            // position: "fixed",
            // zIndex: -1,
            // right: 2,
            // bottom: 1,
          }}
        >
          <img
            src={logo}
            alt="Easy plugins logo"
            height={40}
            // style={{ marginLeft: "90px" }}
          />
        </Box>
      </Box>
    );
  }
}
