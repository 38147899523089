import React, { useEffect, useRef, useState } from "react";
import SortableTree, {
  addNodeUnderParent,
  removeNodeAtPath,
  getNodeAtPath,
  changeNodeAtPath,
} from "@nosferatu500/react-sortable-tree";
import "@nosferatu500/react-sortable-tree/style.css";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import SelectFromModuleFields from "./SelectFromModuleFields";
// import { useSnapshot } from "valtio";
// import { widState } from "../../../Store/workdriveState";
import { getModuleAndLookupDataAndFields } from "../DynamicHashTextField/Functions/helperFunctions";
import { Box, Button, Modal, Typography } from "@mui/material";
import { set } from "react-hook-form";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import "./MySortableTree.css";

export const baseTreeData = [
  {
    title: "${Name}",
    id: 0,
    expanded: true
  },
]

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 430,
  bgcolor: "background.paper",
  borderRadius: "5px",
  // border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const ZOHO = window.ZOHO;

function MySortableTree({
  moduleName,
  myTreeData = baseTreeData,
  savedData,
  allowMultipleRoots = false,
}) {

  const [zohoLoaded, setZohoLoaded] = useState(false);
  const [fieldNames, setFieldNames] = useState([]);
  const [deleteNodeDetails, setDeleteNodeDetails] = useState({});

  const [open, setOpen] = useState(false);
  let row = useRef({});

  const [modalOpen, setModalOpen] = useState(false);
  const handleModalOpen = () => setModalOpen(true);
  const handleModalClose = () => {
    setDeleteNodeDetails({});
    setModalOpen(false);
  };

  const handleNodeDelete = () => {
    const myData = removeNodeAtPath(deleteNodeDetails);

    savedData({
      key: "treeData",
      response: myData,
    });

    handleModalClose();
  };

  useEffect(() => {
    async function initZoho() {
      ZOHO.embeddedApp.on("PageLoad", async function (data) { });

      await ZOHO.embeddedApp.init();
      setZohoLoaded(true);
    }
    initZoho();
  }, []);

  useEffect(() => {
    async function fetchData() {
      if (moduleName && zohoLoaded && moduleName !== "") {
        async function getRecordAndFieldData() {
          try {
            const entityDataAndFields = await getModuleAndLookupDataAndFields(
              moduleName
            );
            let allFieldsData = [];
            Object.keys(entityDataAndFields?.fieldNames).forEach((module) => {
              allFieldsData = [
                ...allFieldsData,
                ...entityDataAndFields?.fieldNames[module],
              ];
            });
            // ;
            setFieldNames(allFieldsData);
          } catch (error) {
            // ;
          }
        }
        getRecordAndFieldData();
      }
    }
    fetchData();
  }, [moduleName, zohoLoaded]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const getNodeKey = ({ treeIndex }) => treeIndex;

  if (!zohoLoaded) {
    return <>Zoho is not loaded yet</>;
  }

  return (
    <div style={{ height: 300 }}>
      <SortableTree
        className={
          myTreeData?.length > 1
            ? ""
            : myTreeData?.[0]?.children && myTreeData?.[0]?.children?.length
              ? ""
              : "oneChild"
        }
        treeData={myTreeData}
        onChange={(treeData) => {
          if (!allowMultipleRoots && treeData?.length == 1) {
            return savedData({ key: "treeData", response: treeData });
          } else if (!allowMultipleRoots && treeData?.length >= 2) {
            return setOpen(true);
          }
          savedData({ key: "treeData", response: treeData });
        }}
        generateNodeProps={({ node, path, treeIndex }) => ({
          title: (
            <SelectFromModuleFields
              moduleFields={fieldNames}
              node={node}
              path={path}
              myTreeData={myTreeData}
              getNodeKey={getNodeKey}
              savedData={savedData}
            />
          ),
          buttons:
            node?.id !== 0
              ? [
                <AddCircleIcon
                  sx={{
                    color: "#1976d2",
                    "&:hover": { cursor: "pointer", color: "#1565c0" },
                  }}
                  onClick={() => {
                    
                    const myData = addNodeUnderParent({
                      treeData: myTreeData,
                      parentKey: path[path.length - 1],
                      expandParent: true,
                      getNodeKey,
                      newNode: {
                        title: ``,
                      },
                    });
                    
                    savedData({
                      key: "treeData",
                      response: myData?.treeData,
                    });
                  }}
                />,
                <RemoveCircleIcon
                  sx={{
                    color: "#ef5350",
                    "&:hover": { cursor: "pointer", color: "#d32f2f " },
                  }}
                  onClick={() => {
                    setDeleteNodeDetails({
                      treeData: myTreeData,
                      path: path,
                      getNodeKey,
                    });
                    handleModalOpen();
                    // if (
                    //   window.confirm(
                    //     `Are you sure you want to delete this node?`
                    //   )
                    // ) {
                    // const myData = removeNodeAtPath({
                    //   treeData: myTreeData,
                    //   path: path,
                    //   getNodeKey,
                    // });

                    // savedData({
                    //   key: "treeData",
                    //   response: myData,
                    // });
                    // setTreeData(myData);
                    // }
                  }}
                />,
              ]
              : [
                <AddCircleIcon
                  sx={{
                    color: "#1976d2",
                    "&:hover": { cursor: "pointer", color: "#1565c0" },
                  }}
                  onClick={() => {
                    // ;
                    const myData = addNodeUnderParent({
                      treeData: myTreeData,
                      parentKey: path[path.length - 1],
                      expandParent: true,
                      getNodeKey,
                      newNode: {
                        title: ``,
                      },
                    });
                    // ;
                    savedData({
                      key: "treeData",
                      response: myData?.treeData,
                    });
                    // setTreeData(myData?.treeData);
                  }}
                />,
              ],
        })}
      />
      <Modal
        open={modalOpen}
        onClose={handleModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            sx={{ textAlign: "center", fontWeight: "medium", fontSize: 18 }}
          >
            Are you certain that you wish to delete this node?
          </Typography>
          <Box sx={{ mt: 2, display: "flex", justifyContent: "center" }}>
            <Button
              onClick={handleNodeDelete}
              sx={{ mr: 2, width: 90 }}
              size="small"
              variant="contained"
            >
              Yes
            </Button>
            <Button
              onClick={handleModalClose}
              sx={{ width: 90 }}
              size="small"
              variant="outlined"
            >
              No
            </Button>
          </Box>
        </Box>
      </Modal>
      <Snackbar open={open} autoHideDuration={2000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          This folder should be at least child folder of root folder!
        </Alert>
      </Snackbar>
    </div>
  );
}

export default MySortableTree;

/**
 * <MyShortableTree moduleName="Contacts" saveData={saveData} />
 *
 */
